import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

/*
  Logging mode can be:  'LOG' ||  'DEBUG' ||  'INFO' || 'WARN' || 'ERROR' || 'NONE'
                        ^ dev     ^ staging             ^ prod
*/
switch (environment.logLevel) {
    case 'NONE':
        console.error = () => {};
        console.warn = () => {};
        console.info = () => {};
        console.debug = () => {};
        console.log = () => {};
        break;
    case 'ERROR':
        console.warn = () => {};
        console.info = () => {};
        console.debug = () => {};
        console.log = () => {};
        break;
    case 'WARN':
        console.info = () => {};
        console.debug = () => {};
        console.log = () => {};
        break;
    case 'INFO':
        console.debug = () => {};
        console.log = () => {};
        break;
    case 'DEBUG':
        console.log = () => {};
        break;
    case 'LOG':
        break;
    default:
        console.error(`Well this is ironic. Invalid logLevel [${environment.logLevel}]`);
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
        //   if (!environment.production) {
        //     const applicationRef = moduleRef.injector.get(ApplicationRef);
        //     const componentRef = applicationRef.components[0];
        //     // allows to run `ng.profiler.timeChangeDetection();`
        //     enableDebugTools(componentRef);
        //   }

        if ('serviceWorker' in navigator && environment.production) {
            navigator.serviceWorker.register('ngsw-worker.js');
        }
    })
    .catch((err) => console.error(err));
