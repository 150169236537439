import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Role } from '../http/flight-club/user/role';
import { AppServicesModule } from '../modules/app-services.module';
import { AuthService } from '../services/auth.service';
import { UtilityService } from '../services/utility.service';

@Injectable({
    providedIn: AppServicesModule,
})
export class RoleAccessGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router, private utils: UtilityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const roleStrings = route.data['role'] as string[];
        const redirectTo = route.data['redirectTo'];

        return this.auth.checkFragment(route.fragment).pipe(
            map((_user) => {
                const userHasPermission = roleStrings.map((role) => _user.hasRole(role)).reduce((acc, cur) => acc || cur, false);
                if (userHasPermission) {
                    // || _user.isAdmin()) {
                    return true;
                } else {
                    if (redirectTo) {
                        return this.router.navigate([redirectTo], { queryParams: route.queryParams });
                    } else {
                        const roles = roleStrings.map((r) => this.utils.getEnumKeyByValue(Role, r)) as Role[];
                        const queryParams = { dest: state.url, role: roles.join(',') };
                        return this.router.navigate(['/access-denied'], { queryParams });
                    }
                }
            }),
            catchError((err) => of(false)),
            mergeMap((result: boolean | Promise<boolean>) => {
                if (typeof result == 'boolean') {
                    return of(result);
                } else {
                    return from(result);
                }
            })
        );
    }
}
