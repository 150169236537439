import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { HttpModule } from 'app/shared/http/http.module';
import { FlightClubBasicCRUDService } from '../flight-club-basic-crud.service';
import { Location } from '../location/location';
import { ApiToken } from './api-token';
import { LivestreamLaunch } from './livestream-launch';
import { SavedSimulation } from './saved-simulation';
import { User } from './user';
import { UserCoupon } from './user-coupon';
import { FlightClubApiService } from '../flight-club-api.service';

@Injectable({
    providedIn: HttpModule,
})
export class UserService extends FlightClubBasicCRUDService<User> {
    resourceEndpoint = 'user';

    constructor(protected override http: HttpClient, protected override fcApiService: FlightClubApiService) {
        super(http, fcApiService);
    }

    getCurrent(): Observable<User | never> {
        return this.http
            .get<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/current`, { headers: this.fcApiService.headers })
            .pipe(take(1));
    }

    logout(): Observable<void | never> {
        return this.http
            .get<void>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/logout`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    /*
        Saved Simulations
    */

    saveSimulation(userId: string, simulation: SavedSimulation): Observable<User | never> {
        return this.http
            .post<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/simulations`, simulation, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    deleteSimulation(userId: string, simulationId: string): Observable<User | never> {
        return this.http
            .delete<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/simulations/${simulationId}`, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    /*
        Favourite Locations
    */

    addFavouriteLocation(userId: string, location: Location): Observable<User | never> {
        return this.http
            .post<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations`, location, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    updateFavouriteLocation(userId: string, locationId: string, location: Location): Observable<User | never> {
        return this.http
            .patch<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations/${locationId}`, location, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    deleteFavouriteLocation(userId: string, locationId: string): Observable<User | never> {
        return this.http
            .delete<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations/${locationId}`, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    reorderFavouriteLocations(userId: string, locations: Location[]): Observable<User | never> {
        return this.http
            .put<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations`, locations, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    /*
        API Tokens
    */

    addApiToken(userId: string, apiToken: ApiToken): Observable<User | never> {
        return this.http
            .post<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/api-tokens`, apiToken, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    deleteApiToken(userId: string, token: string): Observable<User | never> {
        return this.http
            .delete<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/api-tokens/${token}`, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    /*
        Livestreaming
    */

    saveUnlockedLaunches(userId: string, livestreamLaunches: LivestreamLaunch[]): Observable<User | never> {
        return this.http
            .post<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/livestream/unlockedLaunches`, livestreamLaunches, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    setLivestreamBrand(userId: string, brand: string): Observable<User | never> {
        return this.http
            .put<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/livestream/brand`, brand, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    /*
        User Coupons
    */

    addCoupon(userId: string, coupon: UserCoupon): Observable<User | never> {
        return this.http
            .post<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/coupons`, coupon, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    deleteCoupon(userId: string, couponId: string): Observable<User | never> {
        return this.http
            .delete<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/coupons/${couponId}`, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    /*
        Stripe
    */

    setStripeCustomerId(userId: string, stripeCustomerId: string): Observable<User | never> {
        return this.http
            .put<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/stripeCustomerId`, stripeCustomerId, {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }
}
