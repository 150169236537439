import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';

import { PatreonService } from './shared/http/flight-club/patreon/patreon.service';
import { AuthService } from './shared/services/auth.service';
import { InjectSnackbarDirective } from './shared/directives/inject-snackbar.directive';
import { SnackBar } from './shared/modules/snack-bar/snack-bar.service';
import { Dialog } from './shared/modules/dialog/dialog.service';
import { InjectDialogDirective } from './shared/directives/inject-dialog.directive';

@Component({
    selector: 'fc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush // never do this! need to find out why
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild(InjectSnackbarDirective) injectSnackbar: InjectSnackbarDirective;
    @ViewChild(InjectDialogDirective) injectDialog: InjectDialogDirective;

    constructor(
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private snackBar: SnackBar,
        private dialog: Dialog,
        private authService: AuthService,
        private patreonService: PatreonService
    ) { }

    ngOnInit() {
        // this usually takes 2 attempts, so can't use take(1)
        // don't really care about unsubscribing anyway, since there can only be one instance of AppComponent happening
        this.route.fragment
            .pipe(
                filter((fragment: string | null) => fragment != null),
                mergeMap((fragment: string) => this.authService.checkFragment(fragment, true))
            )
            .subscribe();

        // Refresh GEO Pledges
        this.patreonService.updateClubSponsors();
    }

    ngAfterViewInit() {
        // need to do this for all of the snack-bars, dialogs app-wide to work!
        this.snackBar.injectionCmp = this.injectSnackbar;
        this.dialog.injectionCmp = this.injectDialog;

        this.route.queryParams
            .pipe(
                map((params) => params['external']),
                filter((value) => value != null)
            )
            .subscribe({
                next: (externalLink) => {
                    window.open(externalLink, '_blank');

                    // remove this query param from URL after handling
                    this.router.navigate([], { queryParams: { external: null }, queryParamsHandling: 'merge' });
                },
            });

        this.route.queryParams
            .pipe(
                map((params) => params['logout']),
                filter((value) => value != null)
            )
            .subscribe({
                next: (logoutStatus) => {
                    if (logoutStatus === 'success') {
                        this.snackBar.open('You have been successfully logged out', '', 5000);
                        this.cd.detectChanges();
                    }

                    // remove this query param from URL after handling
                    this.router.navigate([], { queryParams: { logout: null }, queryParamsHandling: 'merge' });
                },
            });
    }
}
