import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppPreloadingStrategy } from 'app/app-preloading-strategy';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';

import { RoleAccessGuard } from 'app/shared/access-guards/role-access-guard';

import { InjectSnackbarDirective } from 'app/shared/directives/inject-snackbar.directive';
import { InjectDialogDirective } from 'app/shared/directives/inject-dialog.directive';

import { HttpModule } from 'app/shared/http/http.module';

import { AppServicesModule } from 'app/shared/modules/app-services.module';
import { SnackBarModule } from 'app/shared/modules/snack-bar/snack-bar.module';
import { DialogModule } from 'app/shared/modules/dialog/dialog.module';

import { environment } from '../environments/environment';
import { MyHammerConfig } from 'app/hammer-js.config';

@NgModule({
    declarations: [AppComponent, InjectSnackbarDirective, InjectDialogDirective],
    imports: [
        BrowserModule,

        // Browser Animations are used by some of the @angular/material components
        // Once I completely remove the Material dependency, I can remove this and lose 75kB parsed
        BrowserAnimationsModule,

        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js?version=13', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),

        HammerModule,

        AppServicesModule,
        HttpModule,
        SnackBarModule,
        DialogModule,
    ],
    providers: [AppPreloadingStrategy, RoleAccessGuard, { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }],
    bootstrap: [AppComponent],
})
export class AppModule { }
