import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { catchError, map, take, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';

import { environment } from 'environments/environment';

import { AppServicesModule } from '../modules/app-services.module';
import { SnackBar } from '../modules/snack-bar/snack-bar.service';

import { UserService } from '../http/flight-club/user/user.service';
import { User } from '../http/flight-club/user/user';
import { Location as FCLocation } from '../http/flight-club/location/location';
import { SavedSimulation } from '../http/flight-club/user/saved-simulation';
import { ApiToken } from '../http/flight-club/user/api-token';
import { UserCoupon } from '../http/flight-club/user/user-coupon';
import { FlightClubError } from '../http/flight-club/flight-club-error';
import { UserFactory } from '../http/flight-club/user/user.factory';
import { FlightClubApiService } from '../http/flight-club/flight-club-api.service';

@Injectable({
    providedIn: AppServicesModule,
})
export class AuthService {
    private user: ReplaySubject<User>;
    private user$: Observable<User>;

    constructor(
        private fcApiService: FlightClubApiService,
        private userService: UserService,
        private userFactory: UserFactory,
        private location: Location,
        private snackBar: SnackBar
    ) {
        this.user = new ReplaySubject<User>(1);
        this.user$ = this.user; // TS automatically converts from Subject to Observable
    }

    checkFragment(fragment: string, initial = false): Observable<User> {
        // the initial one (which comes from app component) always needs to do a getCurrent()
        // after that, we don't need to do it again unless there's a new fragment
        if (!initial && (fragment == null || fragment == '')) {
            return this.user$;
        }

        // what if i fuck up and fragment has multiple fragments in it? like
        // ac53de2f-785c-426d-93c7-97fa0178a636#ac53de2f-785c-426d-93c7-97fa0178a636#ac53de2f-785c-426d-93c7-97fa0178a636
        // take first one and scrub the rest

        if (fragment != null && fragment != '') {
            if (fragment.indexOf('#') > -1) {
                const fragments = fragment.split('#').filter((_frag) => _frag.length == 36);
                if (fragments.length > 0) {
                    fragment = fragments[fragments.length - 1];
                }
            }

            if (fragment != null && fragment.length === 36) {
                console.log(`setAccessToken from checkFragment`);
                this.fcApiService.setAccessToken(fragment);
            }
        }

        // const currentUser$ = this.fcApiService.accessToken ? this.userService.getCurrent() : of(new User())
        const currentUser$ = this.userService.getCurrent()

        return currentUser$.pipe(
            catchError((err) => of(new User())),
            tap(() => this.location.replaceState(this.location.path(false))),
            tap(this.setUser.bind(this)),
            map((u) => this.userFactory.create(u)),
            take(1)
        );
    }

    getUser(): Observable<User> {
        return this.user$;
    }

    setUser(_user: User) {
        this.user.next(this.userFactory.create(_user));
    }

    dealWithError(error: FlightClubError): Observable<never> {
        if (!environment.production) {
            console.debug(`as1: ${error}`);
            this.snackBar.open(`Dev: ${error.error.code}: ${error.error.errors[0].message}`, 'Ok', 5000);
        }

        switch (error.error.code) {
            case '0':
            case '400':
                // user not logged in
                break;
            case '403':
                // token no longer valid. need to purge from front end
                this.logout();
                break;
            default:
                this.snackBar.open(`${error.error.code}: ${error.error.errors[0].message}`, 'Ok', 5000);
        }

        return throwError(() => error);
    }

    logout(): void {
        this.userService
            .logout()
            .pipe(take(1))
            .subscribe((res) => {
                console.log('Being logged out!');
                console.log(`setAccessToken from logout`);
                this.fcApiService.setAccessToken(null);
                this.setUser(User.getTemporaryUser());
            });
    }

    saveSimulation(user: User, savedSimulation: SavedSimulation): Observable<User> {
        return this.userService.saveSimulation(user.id, savedSimulation).pipe(tap(this.setUser.bind(this)));
    }

    removeSavedSim(user: User, savedSimulation: SavedSimulation): Observable<User> {
        return this.userService.deleteSimulation(user.id, savedSimulation.resourceId).pipe(tap(this.setUser.bind(this)));
    }

    addNewFavouriteLocation(user: User, newLocation: FCLocation): Observable<User> {
        return this.userService.addFavouriteLocation(user.id, newLocation).pipe(tap(this.setUser.bind(this)));
    }

    updateFavouriteLocation(user: User, location: FCLocation): Observable<User> {
        return this.userService.updateFavouriteLocation(user.id, location.resourceId, location).pipe(tap(this.setUser.bind(this)));
    }

    deleteFavouriteLocation(user: User, location: FCLocation): Observable<User> {
        return this.userService.deleteFavouriteLocation(user.id, location.resourceId).pipe(tap(this.setUser.bind(this)));
    }

    reorderFavouriteLocations(user: User, locations: FCLocation[]): Observable<User> {
        return this.userService.reorderFavouriteLocations(user.id, locations).pipe(tap(this.setUser.bind(this)));
    }

    addApiTokenToUser(user: User, token: ApiToken): Observable<User> {
        return this.userService.addApiToken(user.id, token).pipe(tap(this.setUser.bind(this)));
    }

    deleteApiTokenFromUser(user: User, token: ApiToken): Observable<User> {
        return this.userService.deleteApiToken(user.id, token.token).pipe(tap(this.setUser.bind(this)));
    }

    setBrandName(user: User, brand: string): Observable<User> {
        return this.userService.setLivestreamBrand(user.id, brand).pipe(tap(this.setUser.bind(this)));
    }

    setStripeCustomerId(user: User, customerId: string): Observable<User> {
        return this.userService.setStripeCustomerId(user.id, customerId).pipe(tap(this.setUser.bind(this)));
    }

    addNewCouponToUser(user: User, coupon: UserCoupon): Observable<User> {
        return this.userService.addCoupon(user.id, coupon).pipe(tap(this.setUser.bind(this)));
    }

    deleteCouponFromUser(user: User, coupon: UserCoupon): Observable<User> {
        return this.userService.deleteCoupon(user.id, coupon.resourceId).pipe(tap(this.setUser.bind(this)));
    }
}
