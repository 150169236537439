import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {
    preload(route: Route, load: () => Observable<any>): Observable<any> {
        return route.data && route.data['preload'] ? load() : of(null);
    }
}
