export enum Role {
    CLUB_MEMBER = 'Club Member',
    TRAJECTORY_DESIGNER = 'Trajectory Designer',
    ROCKET_HUNTER = 'Rocket Hunter',
    LAUNCH_PHOTOGRAPHER = 'Launch Photographer',
    LAUNCH_PHOTOGRAPHER_PREMIUM = 'Launch Photographer Premium',
    CLUB_SPONSOR = 'Club Sponsor',
    FREQUENT_FLYER = 'Frequent Flyer',
    FLIGHT_CREW = 'Flight Crew',
    SPACE_PIRATE = 'Space Pirate',

    ADMIN = 'Admin',
}

const map: Map<Role | string, number> = new Map();
map.set(null, 0);
map.set(Role.CLUB_MEMBER, 1);
map.set(Role.TRAJECTORY_DESIGNER, 2);
map.set(Role.ROCKET_HUNTER, 3);
map.set(Role.LAUNCH_PHOTOGRAPHER, 4);
map.set(Role.LAUNCH_PHOTOGRAPHER_PREMIUM, 5);
map.set(Role.CLUB_SPONSOR, 6);
map.set(Role.FREQUENT_FLYER, 7);
map.set(Role.FLIGHT_CREW, 8);
map.set(Role.SPACE_PIRATE, 9);
map.set(Role.ADMIN, 10);

export function compare(role1: Role | string, role2: Role | string): number {
    return map.get(role1) - map.get(role2);
}

export function toNumber(role: Role | string): number {
    return map.get(role);
}
