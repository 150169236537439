export class UserDetails {
    constructor(
        // public googleId: string = null,
        // public patreonId: string = null,
        // public discordId: string = null,
        // public discordInvite: string = null,
        // public stripeCustomerId: string = null,
        // public name: string = null,
        // public email: string = null,
        // public imageUrl: string = null,
        // public patreonPledgeCents: number = null,
        public google: GoogleDetails = null,
        public patreon: PatreonDetails = null,
        public discord: DiscordDetails = null,
        public stripe: StripeDetails = null
    ) {}
}

export class GoogleDetails {
    constructor(
        public userId: string = null,
        public email: string = null,
        public username: string = null,
        public avatarUrl: string = null
    ) {}
}

export class DiscordDetails {
    constructor(
        public userId: string = null,
        public email: string = null,
        public username: string = null,
        public avatarUrl: string = null // public inviteUrl: string = null
    ) {}
}

export class PatreonDetails {
    constructor(
        public userId: string = null,
        public email: string = null,
        public username: string = null,
        public avatarUrl: string = null,
        public patreonPledgeCents: number = null
    ) {}
}

export class StripeDetails {
    constructor(public userId: string = null) {}
}
