import { Injectable } from '@angular/core';

import { InjectSnackbarDirective } from 'app/shared/directives/inject-snackbar.directive';
import { Observable, ReplaySubject } from 'rxjs';
import { SnackBarComponent } from './snack-bar.component';
import { SnackBarModule } from './snack-bar.module';

@Injectable({
    providedIn: SnackBarModule,
})
export class SnackBar {
    private actionSub: ReplaySubject<void>;
    private onAction$: Observable<void>;

    private _injectionCmp: InjectSnackbarDirective;

    constructor() {
        this.actionSub = new ReplaySubject<void>(1);
        this.onAction$ = this.actionSub;
    }

    open(message: string, action?: string, durationMs?: number): SnackBar {
        if (this._injectionCmp == null) {
            console.log(`Attempting to open a snackbar, but the injection component hasn't been instantiated yet`);
            console.log(`Message: ${message}`);

            return null;
        } else {
            const viewContainerRef = this._injectionCmp.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(SnackBarComponent);

            componentRef.instance.message = message;
            componentRef.instance.action = action;
            componentRef.instance.duration = durationMs;

            componentRef.instance._action.subscribe(() => this.action());

            return this;
        }
    }

    action() {
        this._injectionCmp.viewContainerRef.remove();
        this.actionSub.next();
    }

    onAction(): Observable<void> {
        return this.onAction$;
    }

    get injectionCmp() {
        return this._injectionCmp;
    }

    set injectionCmp(injectionCmp: InjectSnackbarDirective) {
        this._injectionCmp = injectionCmp;
    }
}
