<div
    class="overflow-none fixed top-0 left-0 h-screen w-screen bg-black"
    [ngClass]="{
        hidden: !active,
        'bg-opacity-75': visible,
        'bg-opacity-0': !visible
    }"
    (click)="click($event)"
    (swiperight)="swipeRight($event)"
    (swipeleft)="swipeLeft($event)"
></div>
