import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { LoadScreenService } from 'app/components/common/load-screen/load-screen.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { FlightClubApiService } from '../flight-club-api.service';
import { PatronName } from './patron-name';
import { Reward } from './reward';
import { HttpModule } from 'app/shared/http/http.module';

@Injectable({
    providedIn: HttpModule,
})
export class PatreonService {
    resourceEndpoint = 'patreon';

    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService,
        private router: Router,
        private loadScreenService: LoadScreenService,
        private fcApiService: FlightClubApiService
    ) {}

    getRewards(): Observable<Reward[]> {
        return this.http
            .get<Reward[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/rewards`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    getClubSponsors(): Observable<PatronName[]> {
        return this.http
            .get<PatronName[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/clubSponsors`, { headers: this.fcApiService.headers })
            .pipe(
                take(1),
                catchError((err) => {
                    console.debug(`ps1: ${JSON.stringify(err)}`);
                    return throwError(() => err);
                })
            );
    }

    loginWithPatreon() {
        const queryString = this.fcApiService.constructQueryString({
            response_type: 'code',
            client_id: environment.patreon.client_id,
            redirect_uri: `${this.fcApiService.apiURL}/${this.resourceEndpoint}/auth/callback`,
        });

        window.location.href = `https://www.patreon.com/oauth2/authorize?${queryString}`;
    }

    updateClubSponsors(): void {
        let storedPledges: Observable<StoredPledges>;

        // First, check local storage and make sure it's not expired yet
        this.localStorage.delete('fc_geoPledges');
        const storedPledgesString = this.localStorage.get('fc_clubSponsors');
        if (!storedPledgesString) {
            storedPledges = this.fetchAndStoreClubSponsors();
        } else {
            const _storedPledges = JSON.parse(storedPledgesString) as StoredPledges;
            const now = new Date();
            if (!_storedPledges.expiry || _storedPledges.expiry < Math.floor(now.getTime() / 1000.0)) {
                this.localStorage.delete('fc_clubSponsors');
                storedPledges = this.fetchAndStoreClubSponsors();
            } else {
                storedPledges = of(_storedPledges);
            }
        }

        storedPledges
            .pipe(
                take(1),
                map((sp) => sp.pledges.map((p) => p.name))
            )
            .subscribe((names) => {
                if (!environment.production) {
                    names = ['Elizabeth Moran'].concat(names);
                }
                this.loadScreenService.addClubSponsorsToLoadScreen(names);
            });
    }

    private fetchAndStoreClubSponsors(): Observable<StoredPledges> {
        return this.getClubSponsors().pipe(
            map((response) => {
                return {
                    expiry: 60 * 60 * 24 + Math.floor(new Date().getTime() / 1000.0),
                    pledges: response,
                };
            }),
            tap((sp) => this.localStorage.put('fc_clubSponsors', JSON.stringify(sp)))
        );
    }
}

interface StoredPledges {
    expiry: number;
    pledges: any[];
}
