export const environment = {
    production: true,
    logLevel: 'DEBUG',
    flightClubServer: 'https://staging.flightclub.io',
    baseUrl: '/',
    enableDebug: false,
    cesium: {
        bingMapsApiDefaultKey: 'AuSkq2uz4u5s-x4V8aYqtjg-3zdGL9JqyFH04lyiUz491P7Lk6XVjDsN5rDOFrxX',
        ionToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4MTA1NjVmNy0zZWFkLTQ5NWItOWFiYy1hYjY2MDMwZmEzNDEiLCJpZCI6MjkwMywiaWF0IjoxNTM1MTA2MzQwfQ.Q5ET4GSG3SS3uNyzpwQWGGa1EParGZudTY0JSlpMZ8M',
        mapBoxToken: 'pk.eyJ1IjoiZGVjbGFubXVycGh5IiwiYSI6ImNqdDk4NmZpMjBneGw0M251MHM2bm5qdTQifQ.6xrLMdVQoL7FfiSobTHiOg',
        mapTilerKey: 'F5j0tHLoVWBCkgrAI6sW',
    },
    discord: {
        client_id: '917491393323233350',
        redirect_uri: '/discord/auth/callback',
    },
    patreon: {
        client_id: 'cPMNmCE2aqxby_WZXUAJjDvqJAeIrwT4yuERZjs46rz5zR0rKakSsOLpZYPQHlfu',
        redirect_uri: '/patreon/auth/callback',
    },
    google: {
        geolocation: 'AIzaSyAXgniCUdGVqHYidPtTqvg-jvsplS_qPXw',
        maps: 'AIzaSyDpAu1a8u9olXZduappKK76_TFHVHu1LmA',
        signInClientId: '702145055024-brcgtnrnkr3pnarb8bujdq8orh7js5j3.apps.googleusercontent.com',
    },
    openweather: 'b13b852e1bcb6d2e060085e1728d8ce5',
    launchdashboard: 'https://api.launchdashboard.space',
    timezoneDbKey: '6PJX8GG1860W',
    n2yoKey: '3EHVD3-HTDFLH-DK58BQ-3Z3J',
    nasaApiKey: 'buPDEtML8hLSo8vhucVW9AmYYlIXzVphToAbzDEN',
    stripe: {
        pk: 'pk_live_51HF6DNFEy032OLje6pQEe8DttwMZIQmnUm918Qe5muhyiynBrYeFB92Bv3Z6JYZhAVcVxgo0ogqenedcGoOaivBo00VV6pbvrI',
        livestreams: {
            productId: 'prod_KzLXZyojr8QFi9',
            priceId_Basic: 'price_1KJMqFFEy032OLjeD7lXqVrR',
            priceId_Premium: 'price_1KJMqFFEy032OLjett5WVOA2',
            priceId_UpgradeToPremium: 'price_1KJMqFFEy032OLjekvyu6i26',
            priceId_BasicMonthly: 'price_1KJMqFFEy032OLjeume0Zrfx',
            priceId_PremiumMonthly: 'price_1KJMqFFEy032OLjefOyDvMoK',
        },
        photogToolkit: {
            productId: 'prod_KzLbtmK0lbLu4a',
            priceId_RocketHunter: 'price_1KJMubFEy032OLjeRVosKpeU',
            priceId_Basic: 'price_1KJMubFEy032OLje6Hj4U2XH',
            priceId_Premium: 'price_1KJMubFEy032OLjeoJliq5v2',
            priceId_RocketHunterMonthly: 'price_1MfmIRFEy032OLjeyh4mF7sc',
            priceId_BasicMonthly: 'price_1MfmBgFEy032OLjefRKDWhLm',
            priceId_PremiumMonthly: 'price_1MfmC8FEy032OLjeDNW6VYco',
        },
        rewards: {
            productId: 'prod_KzLhP79LZCM9Bl',
            priceId_ClubMember: 'price_1KJN0MFEy032OLjeDvvZbJAz',
            priceId_TrajectoryDesigner: 'price_1KJN0MFEy032OLjeiULdP9Ha',
            priceId_ClubSponsor: 'price_1KJN0MFEy032OLjeQoRuPBxA',
            priceId_FrequentFlyer: 'price_1KJN0MFEy032OLjex4QPZRei',
            priceId_FlightCrew: 'price_1KJN0MFEy032OLje0oBS7hOD',
            priceId_SpacePirate: 'price_1KJN0MFEy032OLje0pBfhfH0',
        },
    },
    spacedevsApiKey: '403ff7ee720ac774960f64957a69962329dfb67e',
};
