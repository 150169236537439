import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { FlightClubApiService } from '../flight-club-api.service';
import { User } from '../user/user';
import { HttpModule } from 'app/shared/http/http.module';

@Injectable({
    providedIn: HttpModule,
})
export class GoogleSignInService {
    resourceEndpoint = 'google';

    constructor(protected http: HttpClient, protected fcApiService: FlightClubApiService) {}

    validateToken(token: string): Observable<User | never> {
        return this.http
            .get<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/validate/${token}`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    unlink(): Observable<User | never> {
        return this.http
            .get<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/unlink`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }
}
