<fc-overlay class="relative z-20"></fc-overlay>
<div class="fixed top-0 left-0 z-30 flex h-screen w-screen items-center justify-center">
    <div class="m-auto flex w-11/12 flex-col rounded bg-noche-500 px-8 py-4 md:w-8/12 lg:w-7/12 xl:w-6/12">
        <div class="flex flex-row items-center justify-between">
            <h3>{{ title }}</h3>
            <button class="btn primary" (click)="close()">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                </svg>
            </button>
        </div>

        <div class="mt-4">
            <ng-container *ngTemplateOutlet="templateRef"></ng-container>
        </div>

        <!-- <ng-content></ng-content> -->
    </div>
</div>
