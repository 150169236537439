import { Injectable, TemplateRef } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

import { InjectDialogDirective } from 'app/shared/directives/inject-dialog.directive';
import { DialogComponent } from './dialog.component';
import { DialogModule } from './dialog.module';

@Injectable({
    providedIn: DialogModule,
})
export class Dialog {
    private closeSub: ReplaySubject<void>;
    private onClose$: Observable<void>;

    private _injectionCmp: InjectDialogDirective;

    constructor() {
        this.closeSub = new ReplaySubject<void>(1);
        this.onClose$ = this.closeSub;
    }

    open(title: string, templateRef: TemplateRef<any>): Dialog {
        if (this._injectionCmp == null) {
            console.log(`Attempting to open a dialog, but the injection component hasn't been instantiated yet`);

            return null;
        } else {
            const viewContainerRef = this._injectionCmp.viewContainerRef;
            const componentRef = viewContainerRef.createComponent(DialogComponent);

            componentRef.instance.templateRef = templateRef;
            componentRef.instance.title = title;

            componentRef.instance._close.subscribe(() => this.close());

            return this;
        }
    }

    close() {
        this._injectionCmp.viewContainerRef.remove();
        this.closeSub.next();
    }

    onClose(): Observable<void> {
        return this.onClose$;
    }

    get injectionCmp() {
        return this._injectionCmp;
    }

    set injectionCmp(injectionCmp: InjectDialogDirective) {
        this._injectionCmp = injectionCmp;
    }
}
