import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AppServicesModule } from '../modules/app-services.module';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from '../services/firebase.service';

@Injectable({
    providedIn: AppServicesModule,
})
export class LoggedInAccessGuard implements CanActivate {
    constructor(private auth: AuthService, private firebase: FirebaseService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // use for accessing 'account' (need to be logged in)
        // or for accessing 'sign-in-or-register' (need to be logged out) <- for this one, we use invert

        const redirectTo = route.data['redirectTo'] || '';
        const invert = (route.data['invert'] as boolean) || false;

        // console.debug('=====================================');
        // console.debug(`Attempting to access ${route.routeConfig.path}...`);

        return this.auth.checkFragment(route.fragment).pipe(
            take(1),
            map((_user) => _user != null && _user.id != null),
            mergeMap((isLoggedIn: boolean) => {
                if (isLoggedIn) {
                    if (!invert) {
                        // want to be logged in, and we are logged in
                        // console.debug(`1: ${isLoggedIn}, ${invert} => true`);
                        return of(true);
                    } else {
                        // don't want to be logged in, but we are logged in
                        // console.debug(`2: ${isLoggedIn}, ${invert} => redirect ${redirectTo}`);
                        return from(this.router.navigate([redirectTo]));
                    }
                } else {
                    return this.firebase.tokenValidationChange().pipe(
                        filter((val) => val === false),
                        map(() => this.firebase.validationState.isLoggedIn),
                        mergeMap((isFirebaseLoggedIn) => {
                            if (isFirebaseLoggedIn) {
                                if (!invert) {
                                    // want to be logged in, and we are logged in
                                    // console.debug(`3: ${isFirebaseLoggedIn}, ${invert} => true`);
                                    return of(true);
                                } else {
                                    // don't want to be logged in, but we are logged in
                                    // console.debug(`4: ${isFirebaseLoggedIn}, ${invert} => redirect ${redirectTo}`);
                                    return from(this.router.navigate([redirectTo]));
                                }
                            } else {
                                // ok we are 100% not logged in, no matter what you say

                                if (!invert) {
                                    // want to be logged in, but we are not logged in
                                    // console.debug(`5: ${isFirebaseLoggedIn}, ${invert} => redirect ${redirectTo}`);
                                    return from(this.router.navigate([redirectTo]));
                                } else {
                                    // don't want to be logged in, and we are not logged in
                                    // console.debug(`6: ${isFirebaseLoggedIn}, ${invert} => true`);
                                    return of(true);
                                }
                            }
                        }),
                        catchError((err) => {
                            // console.debug(`8:`);
                            // console.debug(err);
                            return of(invert);
                        })
                    );
                }
            }),
            catchError((err) => {
                // console.debug(`7:`);
                // console.debug(err);
                return of(invert);
            }) // any errors, don't let user through
        );
    }
}
