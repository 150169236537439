import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, from } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithRedirect, signOut, signInWithPopup } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { AuthService } from './auth.service';
import { GoogleSignInService } from '../http/flight-club/google/google-sign-in.service';
import { AppServicesModule } from '../modules/app-services.module';
import { SnackBar } from '../modules/snack-bar/snack-bar.service';
import { FlightClubApiService } from '../http/flight-club/flight-club-api.service';
import { User } from '../http/flight-club/user/user';

@Injectable({
    providedIn: AppServicesModule,
})
export class FirebaseService {
    /*
        Firebase Docs:
        https://firebase.google.com/docs/auth/web/google-signin#web-version-9_6

        AngularFire: (should be using this instead of bare firebase!)
        https://github.com/angular/angularfire
    */

    private _firebaseValidationState: FirebaseValidationState;
    private _validatingTokenSubject = new BehaviorSubject(false);

    constructor(
        private auth: AuthService,
        private fcApiService: FlightClubApiService,
        private googleSignInService: GoogleSignInService,
        private snackBar: SnackBar
    ) {
        this._firebaseValidationState = {
            validating: false,
            isLoggedIn: false,
        };
        this.initialise();
        this.listenForAuthStateChanges();
    }

    initialise() {
        /*
            Set firebase redirect URL to be /account, and then on that page we can do the authorizeIfRedirect()
        */

        const firebaseConfig = {
            apiKey: 'AIzaSyA4c4Qgka7Jn3Mv-2b6bBwfhpKWByL4M8k',
            authDomain: 'flightclubio.firebaseapp.com',
            databaseURL: 'https://flightclubio.firebaseio.com',
            projectId: 'flightclubio',
            storageBucket: 'flightclubio.appspot.com',
            messagingSenderId: '698533022735',
            appId: '1:698533022735:web:1a9c325337ef5ce756677a',
            measurementId: 'G-FY4XWE0RR2',
        };

        // Initialize Firebase
        initializeApp(firebaseConfig);
    }

    logout() {
        from(signOut(getAuth()))
            .pipe(take(1))
            .subscribe({
                next: () => this.notify(false, false),
                error: () => this.notify(false, false),
            });
    }

    public tokenValidationChange(): Observable<boolean> {
        return this._validatingTokenSubject;
    }

    get validationState(): FirebaseValidationState {
        return this._firebaseValidationState;
    }

    signInWithGoogleAuthProvider(scopes: string[]): void {
        const provider = new GoogleAuthProvider();
        scopes.forEach((scope) => provider.addScope(scope));
        provider.setCustomParameters({
            prompt: 'select_account',
        });
        from(signInWithPopup(getAuth(), provider)).subscribe({
            next: (val) => console.log(val),
            error: (err) => console.error(err),
        });
    }

    listenForAuthStateChanges() {
        this.notify(true, false);

        onAuthStateChanged(
            getAuth(),
            (user) => this.nextAuthStateChange(user),
            (err) => this.authStateChangeError(err)
        );
    }

    nextAuthStateChange(user) {
        this.notify(true, false);

        console.log(user);

        if (user && user['accessToken']) {
            this.googleSignInService
                .validateToken(user['accessToken'])
                .pipe(take(1))
                .subscribe({
                    next: (user) => {
                        this.extractAndSetAccessToken(user);
                        this.auth.setUser(user);
                        this.notify(false, true);
                    },
                    error: (error) => {
                        this.snackBar.open(error.error.message, 'Ok', 5000);
                        this.notify(false, false);
                    },
                });
        } else {
            this.notify(false, false);
        }
    }

    authStateChangeError(err) {
        this.notify(false, false);
        console.error(err);
    }

    notify(isValidating: boolean, isLoggedIn: boolean) {
        this._firebaseValidationState.validating = isValidating;
        this._firebaseValidationState.isLoggedIn = isLoggedIn;
        this._validatingTokenSubject.next(this._firebaseValidationState.validating);
    }

    // authorizeIfRedirect() {
    //     this._firebaseValidationState.validating = true;
    //     this._firebaseValidationState.isLoggedIn = false;
    //     this._validatingTokenSubject.next(true);

    //     from(getRedirectResult(getAuth()))
    //         .pipe(
    //             tap((result: UserCredential) => {
    //                 console.error("0-0:");
    //                 console.error(result);
    //                 if (result == null || result.user == null) {
    //                     this._validatingTokenSubject.next(false);
    //                 }
    //             }),
    //             filter((result) => result != null), // this checks if it was a redirect from an OAuth login
    //             tap((credential) => console.error("Getting Credential...")),
    //             map((result) =>
    //                 GoogleAuthProvider.credentialFromResult(result)
    //             ),
    //             tap((credential) => {
    //                 console.error("0-1:");
    //                 console.error(credential);
    //             }),
    //             filter((credential) => credential != null),
    //             mergeMap((credential) =>
    //                 getAuth().currentUser.getIdToken(true)
    //             ), // the true param forces a refresh of idToken
    //             mergeMap((token) =>
    //                 this.googleSignInService.validateToken(token)
    //             ), // validate with FC backend and get FCUser profile
    //             take(1)
    //         )
    //         .subscribe({
    //             next: (user) => {
    //                 console.error("0-2:");
    //                 console.error(user);
    //                 this.auth.setUser(user);
    //                 this.extractAndSetAccessToken(user);
    //                 this.notify(false, true);
    //             },
    //             error: (error) => {
    //                 console.error("0-3:");
    //                 console.error(error);
    //                 this.snackBar.open(error.error.message, "Ok", 5000);
    //                 this.notify(false, false);

    //             },
    //         });
    // }

    extractAndSetAccessToken(user: User): void {
        const token = user.tokens.sort((a, b) => b.lastAccess - a.lastAccess).map((token) => token.token)[0];
        this.fcApiService.setAccessToken(token);
    }
}

export interface FirebaseValidationState {
    validating: boolean;
    isLoggedIn: boolean;
}
