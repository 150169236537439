import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppPreloadingStrategy } from './app-preloading-strategy';
import { LoggedInAccessGuard } from './shared/access-guards/logged-in-access-guard';
import { RoleAccessGuard } from './shared/access-guards/role-access-guard';
import { Role } from './shared/http/flight-club/user/role';

const routes: Routes = [
    {
        path: '',
        data: { preload: true },
        loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'about',
        data: { preload: false },
        loadChildren: () => import('./components/contact/contact.module').then((m) => m.ContactModule),
    },
    {
        path: 'admin',
        data: { preload: false, role: [Role.ADMIN] },
        canActivate: [RoleAccessGuard],
        loadChildren: () => import('./components/admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'blog',
        data: { preload: false },
        loadChildren: () => import('./components/blog/blog.module').then((m) => m.BlogModule),
    },
    {
        path: 'build',
        data: { preload: false, redirectTo: 'sign-in-or-register' },
        canActivate: [LoggedInAccessGuard],
        loadChildren: () => import('./components/build/build.module').then((m) => m.BuildModule),
    },
    {
        path: 'dashboard',
        data: { preload: true, role: [Role.ROCKET_HUNTER, Role.LAUNCH_PHOTOGRAPHER, Role.LAUNCH_PHOTOGRAPHER_PREMIUM] },
        canActivate: [RoleAccessGuard],
        loadChildren: () => import('./components/dashboard/photographer/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'earth',
        data: { preload: false },
        loadChildren: () => import('./components/dashboard/public/dashboard2.module').then((m) => m.Dashboard2Module),
    },
    {
        path: 'error',
        data: { preload: false },
        loadChildren: () => import('./components/error/error.module').then((m) => m.ErrorModule),
    },
    {
        path: 'interplanetary',
        data: { preload: false },
        loadChildren: () => import('./components/interplanetary/interplanetary.module').then((m) => m.InterplanetaryModule),
    },
    {
        path: 'logout',
        data: { preload: false },
        loadChildren: () => import('./components/logout/logout.module').then((m) => m.LogoutModule),
    },
    {
        path: 'live',
        data: { preload: false },
        loadChildren: () => import('./components/live/live.module').then((m) => m.LiveModule),
    },
    {
        path: 'livestream',
        data: { preload: false },
        loadChildren: () => import('./components/livestream/livestream.module').then((m) => m.LivestreamModule),
    },
    {
        path: 'media',
        data: { preload: false },
        loadChildren: () => import('./components/media/media.module').then((m) => m.MediaModule),
    },
    {
        path: 'pass-planner',
        data: { preload: true },
        loadChildren: () => import('./components/pass-planner/pass-planner.module').then((m) => m.PassPlannerModule),
    },
    {
        path: 'path-tracker',
        data: { preload: true },
        loadChildren: () => import('./components/path-tracker/path-tracker.module').then((m) => m.PathTrackerModule),
    },
    {
        path: 'payload-calculator',
        data: { preload: false },
        loadChildren: () => import('./components/payload-calculator/payload-calculator.module').then((m) => m.PayloadCalculatorModule),
    },
    // {
    //     path: 'payment',
    //     data: { preload: false },
    //     loadChildren: () => import('./components/stripe/stripe.module').then(m => m.StripeModule)
    // },
    {
        path: 'porkchopper',
        data: { preload: false },
        loadChildren: () => import('./components/transfer-calculator/transfer-calculator.module').then((m) => m.TransferCalculatorModule),
    },
    {
        path: 'pricing',
        data: { preload: true },
        loadChildren: () => import('./components/pricing/pricing.module').then((m) => m.PricingModule),
    },
    {
        path: 'result',
        data: { preload: false },
        loadChildren: () => import('./components/result/result.module').then((m) => m.ResultModule),
    },
    {
        path: 'run-simulation',
        data: { preload: false, redirectTo: 'sign-in-or-register' },
        canActivate: [LoggedInAccessGuard],
        loadChildren: () => import('./components/run-simulation/run-simulation.module').then((m) => m.RunSimulationModule),
    },
    {
        path: 'starlink',
        data: { preload: false },
        loadChildren: () => import('./components/starlink/starlink.module').then((m) => m.StarlinkModule),
    },
    {
        path: 'account',
        data: { preload: true, redirectTo: 'sign-in-or-register' },
        canActivate: [LoggedInAccessGuard],
        loadChildren: () => import('./components/user/user.module').then((m) => m.UserModule),
    },
    {
        path: 'sign-in-or-register',
        data: { preload: true },
        loadChildren: () => import('./components/sign-in-or-register/sign-in-or-register.module').then((m) => m.SignInOrRegisterModule),
    },
    {
        path: 'access-denied',
        data: { preload: true },
        loadChildren: () => import('./components/common/fc-common.module').then((m) => m.FcCommonModule),
    },
    // {
    //     path: 'webgl',
    //     data: { preload: false },
    //     loadChildren: () => import('./components/webgl-playground/webgl-playground.module').then(m => m.WebglPlaygroundModule)
    // },
    // {
    //     path: 'typography',
    //     data: { preload: false },
    //     loadChildren: () => import('./components/typography-playground/typography-playground.module').then(m => m.TypographyPlaygroundModule)
    // },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
