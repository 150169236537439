import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { interval, take } from 'rxjs';

@Component({
    selector: 'fc-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent implements OnInit, AfterViewInit {
    @Input() message: string;
    @Input() action: string;
    @Input() duration: number;

    @Output() _action: EventEmitter<void> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        if (this.duration != null) {
            interval(this.duration)
                .pipe(take(1))
                .subscribe(() => this.doAction());
        }
    }

    doAction() {
        this._action.emit();
    }
}
