import { HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { SnackBar } from 'app/shared/modules/snack-bar/snack-bar.service';

export abstract class ApiService {
    protected _apiURL: string;

    constructor(protected snackBar: SnackBar) {}

    get apiURL(): string {
        return this._apiURL;
    }

    constructQueryString(params: any): string {
        if (params === undefined) {
            return '';
        }
        return Object.keys(params)
            .reduce(function (a, k) {
                if (params[k] != null) {
                    a.push(encodeURIComponent(k) + '=' + encodeURIComponent(params[k]));
                }
                return a;
            }, [])
            .join('&');
    }

    dealWithError(error: HttpErrorResponse): Observable<any> {
        console.log(error);
        this.snackBar.open(`${error.status}: ${error.message}`, 'Ok', 5000);
        return throwError(() => error);
    }
}
