import { Injectable } from '@angular/core';

import { EndpointScheme } from '../interfaces/endpointScheme';
import { AppServicesModule } from 'app/shared/modules/app-services.module';

@Injectable({
    providedIn: AppServicesModule,
})
export class UtilityService {
    constructor() {}

    newID(prefix = '') {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        if (prefix != '') {
            if (prefix.length < 3 || prefix.length > 5) {
                throw new Error(`Prefix [${prefix}] length must be 3-5 characters`);
            }
        }
        return prefix + '_' + Math.random().toString(36).substring(2, 11);
    }

    getEnumKeyByValue(myEnum, enumValue) {
        const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : null;
    }

    parseQueryString(queryString) {
        const keyVals = queryString.split('&');
        const paramMap = {};
        for (let i = 0; i < keyVals.length; i++) {
            const keyVal = keyVals[i].split('=');
            const vals = keyVal.length === 2 ? keyVal[1].split('%20') : '';

            paramMap[decodeURIComponent(keyVal[0])] = [];
            for (let j = 0; j < vals.length; j++) {
                paramMap[decodeURIComponent(keyVal[0])].push(decodeURIComponent(vals[j] || ''));
            }
        }
        return paramMap;
    }

    serialize(obj) {
        if (obj === undefined) {
            return '';
        }
        return Object.keys(obj)
            .reduce(function (a, k) {
                a.push(encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]));
                return a;
            }, [])
            .join('&');
    }

    buildEndpoint(scheme: EndpointScheme) {
        let ep = scheme.protocol + '://' + scheme.host;
        if (scheme.port) {
            ep = ep + ':' + scheme.port;
        }
        return ep;
    }

    round(value: number, decimals: number) {
        if ((value + '').indexOf('e') > -1) {
            return 0.0;
        }
        return +(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals);
    }

    noneNaN(...number): boolean {
        // map all numbers to true if number, false if null/NaN. then return (all === true)
        return number.map((n) => n != null && !Number.isNaN(n)).reduce((acc, cur) => acc && cur, true);
    }

    navigateTo(url: string, target?: string): void {
        window.open(url, target);
    }

    isMobile(): boolean {
        const ua = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
    }

    isChrome(): boolean {
        const ua = navigator.userAgent;
        return /Chrome|CriOS/i.test(ua);
    }

    isSafari(): boolean {
        const ua = navigator.userAgent;
        return /Safari/i.test(ua) && !/Chrome/i.test(ua);
    }

    isOS(): boolean {
        const ua = navigator.userAgent;
        return /ipad|iphone/i.test(ua);
    }
}
