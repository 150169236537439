<div class="fixed left-1/4 bottom-0 z-10 flex w-1/2 flex-row items-center justify-center">
    <div class="my-8 w-80 rounded bg-white px-4 py-3 shadow-lg">
        <ng-container *ngIf="action; else noaction">
            <div class="flex flex-row items-center justify-between text-black">
                <div class="text-sm font-normal">{{ message }}</div>
                <a class="cursor-pointer text-black" (click)="doAction()">{{ action }}</a>
            </div>
        </ng-container>

        <ng-template #noaction>
            <div class="flex flex-row items-center justify-start text-black">
                <div class="text-sm font-normal">{{ message }}</div>
            </div>
        </ng-template>
    </div>
</div>
