import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SnackBar } from 'app/shared/modules/snack-bar/snack-bar.service';

import { Observable, throwError } from 'rxjs';

import { environment } from 'environments/environment';

import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { ApiService } from '../api.service';
import { FlightClubError } from './flight-club-error';
import { HttpModule } from 'app/shared/http/http.module';

@Injectable({
    providedIn: HttpModule,
})
export class FlightClubApiService extends ApiService {

    constructor(private localStorage: LocalStorageService, protected override snackBar: SnackBar) {
        super(snackBar);

        const serverRoot = environment.flightClubServer;
        this._apiURL = serverRoot + '/v3';
    }

    get accessToken(): string {
        return this.localStorage.get('accessToken');
    }

    get headers(): HttpHeaders {
        const _headers = {
            'Content-Type': 'application/json'
        };
        if (this.accessToken) {
            _headers['Authorization'] = `Bearer ${this.accessToken}`
        }
        return new HttpHeaders(_headers);
    }

    override dealWithError(error: HttpErrorResponse): Observable<never> {
        console.debug(error);
        const flightClubError = error.error as FlightClubError;
        if (flightClubError != null && flightClubError.error != null) {
            if (flightClubError.error.code !== '500') {
                this.snackBar.open(
                    `${flightClubError.error.code}: ${flightClubError.error.errors[0].reason} ${flightClubError.error.errors[0].message}`,
                    'Ok',
                    5000
                );
            }
        } else {
            this.snackBar.open(`${error.status}: ${error.statusText}`, 'Ok', 5000);
        }
        return throwError(() => flightClubError);
    }

    setAccessToken(accessToken: string) {
        if (accessToken == null) {
            this.localStorage.delete('accessToken');
        } else {
            this.localStorage.put('accessToken', accessToken);
        }
    }
}
