import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FcCommonModule } from 'app/components/common/fc-common.module';

import { DialogComponent } from './dialog.component';
import { OverlayModule } from '../overlay/overlay.module';

@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, OverlayModule],
    exports: [DialogComponent],
})
export class DialogModule {}
