import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, shareReplay, take } from 'rxjs/operators';

import { FlightClubApiService } from './flight-club-api.service';
import { DatabaseResource } from './database-resource';

export abstract class FlightClubBasicCRUDService<T extends DatabaseResource> {
    protected abstract resourceEndpoint: string;

    constructor(protected http: HttpClient, protected fcApiService: FlightClubApiService) { }

    prepareForDatabaseEntry(resource: T): T {
        return resource;
    }

    getOne(id: string): Observable<T | never> {
        return this.http
            .get<T>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${id}`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    getAll(): Observable<T[] | never> {
        return this.http
            .get<T[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    getAllProjected(): Observable<T[] | never> {
        return this.http
            .get<T[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/projected`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    create(resource: T): Observable<T | never> {
        return this.http
            .post<T>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}`, this.prepareForDatabaseEntry(resource), {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    update(id: string, resource: T): Observable<T | never> {
        return this.http
            .put<T>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${id}`, this.prepareForDatabaseEntry(resource), {
                headers: this.fcApiService.headers,
            })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }

    delete(id: string): Observable<void | never> {
        return this.http
            .delete<void>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${id}`, { headers: this.fcApiService.headers })
            .pipe(take(1), catchError(this.fcApiService.dealWithError.bind(this.fcApiService)));
    }
}
