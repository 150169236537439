import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'fc-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
    @Input() title: string;
    @Input() templateRef: TemplateRef<any>;

    @Output() _close: EventEmitter<void> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    close() {
        this._close.emit();
    }
}
