import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Messages } from 'app/shared/interfaces/messages';
import { take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadScreenService {
    messages: Messages;
    clubSponsors: string[];

    constructor(private http: HttpClient) {
        this.http
            .get('assets/load-screen.messages.json')
            .pipe(take(1))
            .subscribe((json: any) => {
                this.messages = json.messages;
                if (this.clubSponsors) {
                    this.addClubSponsorsToLoadScreen(this.clubSponsors);
                }
            });
    }

    addClubSponsorsToLoadScreen(clubSponsors: any[]) {
        if (this.messages) {
            Object.keys(this.messages).forEach((key) => {
                this.messages[key].unshift({
                    p: 0.95,
                    message: '##clubSponsors##',
                });
            });
        } else {
            this.clubSponsors = clubSponsors;
        }
    }

    get clubSponsorsProcessedString(): string {
        return this.clubSponsors?.join('          ');
    }
}
